import React from 'react';

import { Form, Container, Col, Row, InputGroup, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'
import { BiHelpCircle as HelpIcon } from 'react-icons/bi'

import Loading from '../components/Loading';

import { Formik, Field } from 'formik';
import * as yup from 'yup';

import firebase from 'firebase';

import * as axios from 'axios';

import  { Redirect } from 'react-router-dom'

export default class CreateAccount extends React.Component {

    constructor(props) {
        super(props);
        let app = firebase.app();
        let firestore = app.firestore();
        let collection = firestore.collection('teamroles');

        this.state = {
            team: null,
            sendToConsole: false,
            sendToHomePage: false,
            collection: collection,
            user: null,
            validationShape: null,
            submitting: false,
        };
    }

    componentDidMount() {
        const collection = this.state.collection;
        this.userListen(firebase, collection);

        const headers = {
            'content-type': "application/json",
            'accept': "appliation/json"
        }

        const getValidationSchema = {
            url: 'https://gateway.meet-cruiser.com/getvalidationschema',
            method: 'get',
            headers: headers,
            responseType: 'json',
        }

        // axios.request(getValidationSchema)
        //     .then((response) => {
        //         this.setState({
        //             validationShape: response.data
        //         });
        //         console.log(response.data);
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });

    }

    userListen(firestore, collection) {
        firebase.auth().onAuthStateChanged((user) => {
          console.log("SplashPage reports a sign in event");
          var authorized = true;
          if (user) {
              const query = collection.where('allRoles', 'array-contains', user.uid);
              query.get().then((ref) => {
                  var doc = ref.docs[0];
                  if (doc) {
                      console.log("Account already exists");
                      var team = doc.id;
                      this.setState({
                         team: team,
                         sendToConsole: true,
                      });
                  } else {
                      console.log("User does not yet have account")
                      this.setState({
                          user: user,
                      });
                  }
              });
          } else {
              console.log("User signed out.")
              this.setState({
                  sendToHomePage: true,
              });
          }
        });
    }

    getTeamData = (uid) => {

    }

    submitCreateRequest = (formData) => {
        this.setState({
            submitting: true,
        });
        Object.keys(formData).forEach(k => (!formData[k] && formData[k] !== undefined) && delete formData[k]);
        console.log("hit");
        const headers = {
            'content-type': "application/json",
            'accept': "appliation/json"
        }

        var body = formData;
        body.firebaseAuthUid = this.state.user.uid;

        console.log(body);

        const submitCreateAccount = {
            url: 'https://gateway.meet-cruiser.com/createaccount',
            method: 'post',
            headers: headers,
            data: body,
            responseType: 'json',
            timeout: 10000,
        }

        axios.request(submitCreateAccount)
            .then((response) => {
                console.log(response);
                this.setState({
                    team: body.teamHandle,
                    sendToConsole: true,
                    submitting: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    submitting: false,
                });
            });
    }

    render() {
        yup.addMethod(yup.string, 'handleUnique', function(msg) {
            const { message } = msg;
            return this.test('handleUniqueTest', msg, function(value) {
                const { path, createError } = this;
                return new Promise((resolve, reject) => {
                    let app = firebase.app();
                    let firestore = app.firestore();
                    let collection = firestore.collection('teamroles');
                    if (value) {
                        const query = collection.doc(value);
                        query.get()
                            .then((ref) => {
                                if (ref.exists) {
                                    console.log("Handle exists");
                                    reject(createError( {path, msg }));
                                } else {
                                    console.log("Handle availiable")
                                    resolve(true);
                                }
                            });
                    } else {
                        resolve(true);
                    }
                });
            });
        });

        var schema = yup.object().shape({
          firstName: yup.string("Invalid name")
            .required("First name is required")
            .matches(/^[a-z/A-Z/-]+$/, "Only letters allowed")
            .trim(),
          lastName: yup.string("Invalid name")
            .required("Last name is required")
            .matches(/^[a-z/A-Z/-]+$/, "Only letters allowed")
            .trim(),
          position: yup.string("Invalid position")
            .required("Position in your team is required"),
          teamName: yup.string("Invalid name")
            .required("Team name is required"),
          teamHandle: yup.string("Invalid handle")
            .handleUnique("Handle already taken")
            .matches(/^[a-z/0-9]+$/, "Only lower case letters and numbers allowed")
            .trim()
            .required("Team handle is required"),
          email: yup.string("Invalid email")
            .email("Please enter a valid email."),
          city: yup.string("Invalid city name")
            .matches(/^[a-z/A-Z/-]+$/, "Only letters allowed")
            .trim(),
          state: yup.string()
            .min(2, "Please use two letter code")
            .max(2, "Please use two letter code")
            .matches(/^[A-Z]+$/, "Please use two letter code")
            .uppercase()
            .trim(),
          zip: yup.string()
            .required("Zipcode is required")
            .matches(/^[0-9/-]+$/, "Please enter valid zip"),
          testerkey: yup.string()
            .required("Alpha tester key required"),
          terms: yup.bool()
            .required("Please review the terms of use."),

       });

       // var shape = this.state.validationShape;
       // console.log("shape: " + shape);
       // var schema = yup.object().shape(shape);

       return (
           <>
           {this.state.user ?
                <Container fluid="true">
                    <Row style={{height: '8vw'}}/>
                    <Row>
                        <Col xl={3} />
                        <Col xl={6}>
                        { this.state ?
                            <Formik
                                validationSchema={schema}
                                onSubmit={this.submitCreateRequest}
                                initialValues={{
                                    firstName: '',
                                    lastName: '',
                                    position: '',
                                    teamName: '',
                                    teamHandle: '',
                                    email: '',
                                    city: '',
                                    state: '',
                                    zip: '',
                                    terms: false,
                                    testerkey: '',
                                }}
                            >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                handleReset,
                                dirty,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="validationFormik01">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                            type="text"
                                            name="firstName"
                                            placeholder="eg. Dave"
                                            value={values.firstName}
                                            onChange={handleChange}
                                            isValid={touched.firstName && !errors.firstName}
                                            isInvalid={touched.firstName && errors.firstName}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationFormik1-50">
                                            <Form.Label>Alpha Test Key</Form.Label>
                                            <Form.Control
                                            type="text"
                                            name="testerkey"
                                            placeholder="eg. uiewqnv823"
                                            value={values.testerkey}
                                            onChange={handleChange}
                                            isValid={touched.testerkey && !errors.testerkey}
                                            isInvalid={touched.testerkey && errors.testerkey}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.testerkey}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationFormik02">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                            type="text"
                                            name="lastName"
                                            placeholder="eg. Durden"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            isValid={touched.lastName && !errors.lastName}
                                            isInvalid={touched.lastName && errors.lastName}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                                        </Form.Group>

                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationFormik03">
                                            <Form.Label>Position</Form.Label>
                                            <Form.Control
                                            type="text"
                                            name="position"
                                            placeholder="eg. Men's Head Coach"
                                            value={values.position}
                                            onChange={handleChange}
                                            isValid={touched.position && !errors.position}
                                            isInvalid={touched.position && !!errors.position}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.position}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationFormik04">
                                            <Form.Label>Zip</Form.Label>
                                            <Form.Control
                                            type="text"
                                            placeholder="eg. 80909"
                                            name="zip"
                                            value={values.zip}
                                            onChange={handleChange}
                                            isInvalid={touched.zip && !!errors.zip}
                                            isValid={touched.zip && !errors.zip}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.zip}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <hr />
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationFormik05">
                                            <Form.Label>Team Full Name</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                type="text"
                                                placeholder="eg. USA Swimming National Team"
                                                name="teamName"
                                                value={values.teamName}
                                                onChange={handleChange}
                                                isValid={touched.teamName && !errors.teamName}
                                                isInvalid={touched.teamName && !!errors.teamName}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.teamName}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" >
                                            <Form.Label>
                                                Team Handle <OverlayTrigger
                                                  key="teamHandleHelp"
                                                  placement="top"
                                                  overlay={
                                                    <Tooltip id={`tooltip-teamHandleHelp`}>
                                                        Your meet's status will be publicly displayed at this address
                                                    </Tooltip>
                                                  }
                                                >
                                                    <HelpIcon/>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <InputGroup>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrepend">www.meet-cruiser.com/</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                type="text"
                                                placeholder="usa"
                                                name="teamHandle"
                                                value={values.teamHandle}
                                                onChange={handleChange}
                                                isValid={touched.teamHandle && !errors.teamHandle}
                                                isInvalid={touched.teamHandle && errors.teamHandle}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.teamHandle}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                    </Form.Row>
                                    { false ? <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationFormik07">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control
                                            type="text"
                                            placeholder="Colorado Springs"
                                            name="city"
                                            value={values.city}
                                            onChange={handleChange}
                                            isInvalid={touched.city && !!errors.city}
                                            isValid={touched.city && !errors.city}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.city}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationFormik08">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control
                                            type="text"
                                            placeholder="CO"
                                            name="state"
                                            value={values.state}
                                            onChange={handleChange}
                                            isInvalid={touched.state && !!errors.state}
                                            isValid={touched.state && !errors.state}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.state}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationFormik09">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                            type="text"
                                            name="email"
                                            placeholder="eg. example.email@olympics.us.com"
                                            value={values.email}
                                            onChange={handleChange}
                                            isValid={touched.email && !errors.email}
                                            isInvalid={touched.email && errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    :
                                    <> </>
                                    }
                                    <Form.Group>
                                        <Form.Check
                                        required
                                        name="terms"
                                        label="Agree to terms and conditions"
                                        onChange={handleChange}
                                        isInvalid={!!errors.terms}
                                        feedback={errors.terms}
                                        id="validationFormik0"
                                        />
                                    </Form.Group>
                                    {this.state.submitting ?
                                        <Button variant="primary" disabled>
                                            <Spinner
                                              as="span"
                                              animation="grow"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                            />
                                            Creating Account...
                                        </Button>
                                    :
                                        <Button type="submit">Submit form</Button>
                                    }
                                </Form>
                            )}
                            </Formik>
                            :
                            <>
                            <Loading /></>
                        }
                        </Col>
                    </Row>
                </Container>
                :
                <Loading />
                }
                {this.state.sendToConsole ?
                    <Redirect to={`/${this.state.team}/console`}/>
                :
                    ""
                }
                {this.state.sendToHomePage ?
                    <Redirect to={`/`}/>
                :
                    ""
                }
            </>
        );
    }
}
