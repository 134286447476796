import React from "react";
import ScrollableAnchor from 'react-scrollable-anchor'
import { goToAnchor } from 'react-scrollable-anchor'
import './styles/Client.css'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

export default class ClientClass extends React.Component {
    constructor(props) {
        super(props);
    }

    renderSection = (start, index) => {
        return (
            <div>
            <Accordion>
            <ScrollableAnchor id={'start_' + index}>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey={'id:' + index}>
                      Click me!
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={'id:' + index}>
                      <Card.Body>start_{index}</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </ScrollableAnchor>
            </Accordion>
            <div style={{ 'min-height': '1vh' }} />
            </div>
        );
    }

    render() {
        return (
            <div className="client-div">
                <button onClick={() => goToAnchor("start_90")}>
                    Menu
                </button>
                { (this.props.heatsheet.starts) ?
                    this.props.heatsheet.starts.map(this.renderSection) :
                    "Loading..." }
            </div>
        )
    }
}
