import React from 'react';
import Spinner from 'react-bootstrap/Spinner'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import './styles/SignIn.css'

export default class PleaseSignIn extends React.Component {
    render() {
        return (
            <Container className="SignIn">
                <Row style={{'min-height': '45vh'}} />
                <Row>
                    <Col className="SignInText">
                        Please sign in to continue.
                    </Col>
                </Row>
                <Row style={{'min-height': '2vh'}} />
                <Row>
                    <Col>
                        <Button className="SignInButton" onClick={() => this.props.signIn()}> Sign in with Google </Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}
