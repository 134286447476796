import React from 'react';
import './styles/Board.css';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner'
import { UpcomingCards, TestCard } from '../components/UpcomingCards'
import { Navbar, Nav, NavDropdown, NavItem } from 'react-bootstrap'

export default class BoardClass extends React.Component {

    render() {
          return (
            <>
            { this.props.beta ?
                <Navbar bg="primary" variant="dark" sticky="top" bsPrefix="beta">
                    Have feedback? <a className="beta-link" href="https://forms.gle/mcJGG82T8LNa75zd9">Share it with us.</a>
                </Navbar>
                :
                ""
            }
            { this.props.heatsheet && this.props.heatsheet.starts ?
                    <Container fluid={true}>
                        <Row style={{ 'minHeight': '2vh' }} >
                        </Row>
                        <Row style={{ 'minHeight': '26vh' }}>
                            <Col md={{span:12, offset:0}} >
                                <CardDeck className="top-deck">
                                    <TestCard heading="Current Race" heatsheet={this.props.heatsheet} raceNo={this.props.currentstart} />
                                    <TestCard heading="Next Race" heatsheet={this.props.heatsheet} raceNo={this.props.currentstart + 1} />
                                </CardDeck>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{span:10, offset:1}}>
                                <Card className="Upcoming-divider-deck">
                                    <Card.Header className="Upcoming-divider">Upcoming Races...</Card.Header>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ 'minHeight': '10vh' }}>
                            <Col>
                                <CardDeck className="bottom-deck">
                                    <UpcomingCards {...this.props}/>
                                </CardDeck>
                            </Col>
                        </Row>
                    </Container>
                :
                    <Container>
                        <Row style={{'minHeight': '47vh'}} />
                        <Row>
                            <Col className="Loading">
                                <Spinner animation="border" />
                            </Col>
                        </Row>
                    </Container>
            }
            </>
          );
      }
}
