import React from 'react';
import './styles/Board.css';

export default class TestBoard extends React.Component {
    render() {
        return (
            <div className="testMastercontainer">

            </div>
        )
    }
}