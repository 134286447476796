import app from 'firebase/app';
import ReactGA from 'react-ga';

const config = {
    apiKey: "AIzaSyCW2TmnoaOSGjTCkOYGfvIhvmj36_luE8Y",
    authDomain: "meet-status.firebaseapp.com",
    databaseURL: "https://meet-status.firebaseio.com",
    projectId: "meet-status",
    storageBucket: "meet-status.appspot.com",
    messagingSenderId: "764299763861",
    appId: "1:764299763861:web:f17bf6b3c2208d75"
  };

class Firebase {
  constructor() {
    app.initializeApp(config);
    ReactGA.initialize('UA-143165382-1');
  }
}
export default Firebase;
