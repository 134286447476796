import React from 'react'
import Dropzone from 'react-dropzone'
import firebase from 'firebase';

var storageRef;

function MyDropzone() {
    storageRef = firebase.storage().ref();
    return (
      <Dropzone
      multiple={false}
      onDropAccepted={acceptedFiles => /*sendFiles(acceptedFiles)*/ console.log("accepted the files")}
      maxSize={200000}
      onDropRejected={rejectedFiles => console.log("File too big")}>
        {({getRootProps, getInputProps}) => (
          <section>
            <div {...getRootProps()} className="dropContianer" >
              <input {...getInputProps()}  />
              <div style={{height: '4vh'}} />
              <p className="inner">Drag a heatsheet or click to select one</p>
              <div style={{height: '4vh'}} />
            </div>
          </section>
        )}
      </Dropzone>
    );
}

function sendFiles(acceptedFiles) {
    console.log(acceptedFiles)
    var latestHeatSheet = storageRef.child('kedronhillscruisers/heatsheets/2019-championship-morning.pdf');
    latestHeatSheet.put(acceptedFiles[0]).then((snapshot) => {
        console.log("Uploaded file");
    });
}

export default MyDropzone;
