import React from 'react';
import Spinner from 'react-bootstrap/Spinner'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class Loading extends React.Component {
    render() {
        return (
            <Container>
                <Row style={{'min-height': '45vh'}} />
                <Row>
                    <Col className="Loading">
                        <Spinner animation="border" />
                    </Col>
                </Row>
            </Container>
        );
    }
}
