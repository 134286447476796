import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import DropHeatsheet from '../components/DropHeatsheet'

export default class Admin extends React.Component {
    render() {
        return (
            <Container>
                <Row style={{'min-height': '40vh'}} />
                <Row>
                    <Col className="Uploadcol">
                        <DropHeatsheet />
                    </Col>
                </Row>
            </Container>
        );
    }
}
