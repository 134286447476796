import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import TeamRouter from './TeamRouter.js';
import Firebase, { FirebaseContext } from './Firebase';
import 'bootstrap/dist/css/bootstrap.css';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-143165382-1');

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <TeamRouter />
  </FirebaseContext.Provider>,
  document.getElementById('root'),
);
