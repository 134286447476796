import React from 'react';
import './styles/Control.css'
import firebase from 'firebase';
import Loading from '../components/Loading'
import PleaseSignIn from '../components/PleaseSignIn'
import NotAuthorized from '../components/NotAuthorized'
import DropHeatsheet from '../components/DropHeatsheet'
import ControllerNavBar from '../navigation/ControllerNavBar'
import RaceController from './RaceController'
import Admin from './Admin'
import { Router, Route, Link, Redirect } from "react-router-dom";

export default class ControlClass extends React.Component {

    constructor(props) {
        super(props);
        let app = firebase.app();
        let firestore = app.firestore();
        let collection = firestore.collection('meetstatus');
        let docRef = collection.doc(this.props.team);
        this.state = {
            docRef: docRef,
            user: true,
        };
        this.userListen(firestore);
    }

    userListen(firestore) {
        firebase.auth().onAuthStateChanged((user) => {
          console.log("Controller reports a sign in event");
          var authorized = true;
          if (user) {
              console.log(this.props.team);
              firestore.collection('teamroles').doc(this.props.team).get().then(doc => {
                  let data = doc.data();
                  if (data.roles[user.uid] === "controller") {
                      console.log("User authorized")
                      authorized = true;
                  } else {
                      console.log(`User not authorized ${user.uid}`)
                  }
                  this.setState({
                      authorized: authorized,
                  });
                  this.setState({
                      user: user,
                  });
                  this.setState({
                      signingIn: false,
                  });
              });
          } else {
              this.setState({
                  authorized: authorized,
              });
              this.setState({
                  user: user,
              });
              this.setState({
                  signingIn: false,
              });
              console.log("User signed out.")
          }
        });
    }

    componentDidMount() {
        this.setState({
            signingIn: true,
        });
    }


    render() {
        return (
            <div>
            {this.props ?
                (true ?
                    this.renderContent(this.state, this.props)
                :
                    (this.state.signingIn ?
                        <Loading />
                    :
                        <PleaseSignIn signIn={this.props.signIn}/>)
                )
            :
                <Loading />
            }
            </div>
        );
    }

    changeRace = (differential) => {
        if (this.state.docRef) {
            this.state.docRef.update({currentstart: this.props.currentstart + differential});
        }
    };

    raceCtrl = ({ match }) => (
        <RaceController  {...this.props} {...this.state} />
    );

    admin = ({ match }) => (
        <Admin {...this.props} {...this.state} />
    );

    redirect = ({match}) => (
        <Redirect to={`/${this.props.team}/console/control`}/>
    );

    renderContent(state, props) {
        return (
            <div>
            {true ?
                <>
                <ControllerNavBar {...this.props}/>
                <Route exact path={`/${this.props.team}/console/control`} component={this.raceCtrl}/>
                <Route exact path={`/${this.props.team}/console/admin`} component={this.admin}/>
                <Route exact path={`/${this.props.team}/console`} component={this.redirect}/>
                </>
            :
                <NotAuthorized signOut={this.props.signOut}/>
            }
            </div>

        );
    }

}
