import React from 'react'
//for navbar
import { Navbar, Nav, NavDropdown, NavItem } from 'react-bootstrap'
import { BiLinkExternal as ExternalLink } from 'react-icons/bi'
import { LinkContainer } from "react-router-bootstrap";

//Style (not nav bar)
import './styles/SplashPage.css';

//Content
import { Container, Row, Col, Button, Form, Spinner } from 'react-bootstrap';

//Page routing
import  { Redirect } from 'react-router-dom'
import firebase from 'firebase';


export default class SplashPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            authorized: true,
            user: null,
            team: null,
            goToConsole: false,
            goToCreateAccount: false,
        }
        let app = firebase.app();
        let firestore = app.firestore();
        let collection = firestore.collection('teamroles');
        this.userListen(firestore, collection);
    }

    userListen(firestore, collection) {
        firebase.auth().onAuthStateChanged((user) => {
          console.log("SplashPage reports a sign in event");
          var authorized = true;
          if (user) {
              const query = collection.where('allRoles', 'array-contains', user.uid);
              query.get().then((ref) => {
                  var doc = ref.docs[0];
                  if (doc) {
                      var team = doc.id;
                     this.setState({
                         team: team,
                     });
                  } else {
                      console.log("Team document not found")
                      this.setState({
                          goToCreateAccount: true,
                      });
                  }
                  this.setState({signingIn: false,});
              });
          } else {
              this.setState({
                  team: null,
              });
              this.setState({
                  goToConsole: false,
              });
              console.log("User signed out.")
              this.setState({signingIn: false,});
          }
        });
    }

    componentDidMount() {
        this.setState({signingIn: true,});
    }

    navBar = (props, state) => {
        return (
            <Navbar className="padded-nav-bar" collapseOnSelect bg="primary" variant="dark" expand="lg" fixed="top" >
                <Navbar.Brand href="/">Meet Cruiser</Navbar.Brand>
                <Nav className="mr-auto">
                </Nav>
                {/* <Nav>
                    <Form inline>
                        {state.signingIn ?
                            (
                            <Button variant="primary" disabled>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                Signing In...
                             </Button>)
                        :
                            (this.state.team ?
                                (<Button onClick={() => {
                                    this.setState({
                                        goToConsole: true,
                                    });
                                }} style={{'fontWeight': 'bold'}}>
                                    Go to Console
                                </Button>)
                            :
                                (<Button onClick={() => {
                                    this.setState({
                                        signingIn: true,
                                    });
                                    this.props.signIn();
                                }} style={{'fontWeight': 'bold'}}>
                                    Sign In
                                </Button>)
                            )
                        }
                        {state.team ? <Button onClick={() => this.props.signOut()}>Sign Out</Button> : ""}
                    </Form>
                </Nav> */}
            </Navbar>
        );
    }




    redirect = (props) => {
        console.log("called");
        if (props) {
            return <Redirect to={`/${this.state.team}/console`}/>
        } else {
            return <Redirect to='/failed'/>
        }
    }

    sendToCreateAccount = (props) => {
        console.log("headed to create account")
        if (props) {
            return <Redirect to={`/createaccount`}/>
        } else {
            return <Redirect to='/failed'/>
        }
    }

    // <Image src="https://firebasestorage.googleapis.com/v0/b/meet-status.appspot.com/o/images%2FpoolBackground.jpg?alt=media&token=a4b19348-3085-4d3c-b1f2-df7e744e8b4d"
    // bsPrefix="backGroundCover" className="backGroundCover"/>

    render() {
        return <Redirect to='/championship'/> 

        return (
            <>
            {this.navBar(this.props, this.state)}
            <div className="background" >
                <Container fluid={true} className="headingtext">
                    <Row style={{height: '25vh'}} />
                    <Row style={{height: '25vh'}}>
                        <Col>
                            <div className="getStarted">Welcome to</div>
                            <div className="getStarted">Meet Cruiser!</div>
                        </Col>
                    </Row>
                    <Row style={{height: '9.5vh'}}>
                        <Col>
                            <div className="payAsYouGo">Looking for the championship meet?</div>
                            <div className="payAsYouGo"><a className="payAsYouGo" href="/championship">Click here!</a></div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col>
                            <Button variant="outline-primary" className="getStartedButton" onClick={() => {
                                this.setState({signingIn: true,});
                                this.props.signIn();
                            }}>TRY US OUT</Button>
                        </Col>
                    </Row> */}
                </Container>
                {this.state.team && this.state.goToConsole ?
                    this.redirect(this.props)
                    :
                    ""
                }
                {this.state.goToCreateAccount ?
                    this.sendToCreateAccount(this.props)
                :
                    ""
                }
            </div>
            </>
        );
    }


    renderContent(state, props) {
        return (
            <div>
            </div>
        );
    }

}
