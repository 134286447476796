import React from 'react'
import { Navbar, Nav, NavDropdown, NavItem } from 'react-bootstrap'
import { BiLinkExternal as ExternalLink } from 'react-icons/bi'
import { LinkContainer } from "react-router-bootstrap";

export default class ControllerNavBar extends React.Component {
    constructor(props) {
        super(props);
        console.log(props.match.params);
    }
    render() {
        return (
            <Navbar className="padded-nav-bar" collapseOnSelect bg="primary" variant="dark" expand="lg" sticky="top" >
                <Navbar.Brand href={`/`}>Cruiser Console</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <LinkContainer to={`/${this.props.team}/console/admin`} ><Nav.Link>Admin</Nav.Link></LinkContainer>
                        <LinkContainer to={`/${this.props.team}/console/control`} ><Nav.Link>Controller</Nav.Link></LinkContainer>
                    </Nav>
                    <Nav >
                        <Navbar.Text style={{padding: '50'}}>Go to <a href={`/${this.props.match.params.team}`} target="_blank">Client <ExternalLink/></a> or <a href={`/${this.props.match.params.team}/display_board`} target="_blank">Display Board <ExternalLink /></a></Navbar.Text>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }

}
