import React from "react";
import { Router, Route, Switch, BrowserRouter } from "react-router-dom";
import firebase from 'firebase';
import ReactGA from 'react-ga'
import RouteClass from './Route.js'
import SplashPage from './views/SplashPage.js'
import CreateAccount from './views/CreateAccount.js'


export default class TeamRouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            signIn: this.googleSignIn,
            signOut: this.googleSignOut,
        }
    }

    componentDidMount() {
    	ReactGA.pageview(window.location.pathname);
    }

    routerView = (props) => {
        return (
            <RouteClass {...this.state} {...props} {...this.props} />
        );
    }

    splashPage = (props) => {
        return (
            <SplashPage {...this.state} {...props} {...this.props} />
        );
    }

    createAccount = (props) => {
        return (
            <CreateAccount {...this.state} {...props} {...this.props} />
        );
    }

    googleSignIn = () => {
        console.log("signing in")
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithRedirect(provider);
    }

    googleSignOut = () => {
        firebase.auth().signOut().then(function() {
          console.log("User signed out.")
        }).catch(function(error) {
          console.log("User could not be signed out.")
        });
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                        <Route exact path={`/createaccount`} component={this.createAccount} />
                        <Route path={`/:team`} component={this.routerView} />
                        <Route exact path={`/`} component={this.splashPage} />
                </Switch>
            </BrowserRouter>
        );
    }
};
