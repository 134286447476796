

export default class Utility {

    static jsonCopy(src) {
        return JSON.parse(JSON.stringify(src));
    }

    static getStrokeLongText(includeSex, includeAge, shortenName, includeDistance, heatsheet, startNo) {
        return this.getStrokeCombined(includeSex, includeAge, shortenName, includeDistance, heatsheet, startNo)
    }

    static getStrokeText(includeSex, includeAge, heatsheet, startNo) {
        return this.getStrokeCombined(includeSex, includeAge, true, false, heatsheet, startNo);
    }

    static getStrokeCombined(includeSex, includeAge, shortenName, includeDistance, heatsheet, startNo) {
        if (startNo < 0 || startNo > heatsheet.starts.length - 1) {
             return "";
        }
        let heats = heatsheet.starts[startNo].heats;
        var noHeats = heats.length;
        var ages = [];
        var sexesByAge = {};
        heats.forEach(function(heat) {
            if (!ages.includes(heat.ageGroup)) {
                ages.push(heat.ageGroup);
                sexesByAge[heat.ageGroup.replace(/ /g,"_")] = [];
            }
            sexesByAge[heat.ageGroup.replace(/ /g,"_")].push(heat.sex);
        });
        let buildingString = "";
        ages.forEach(function(age) {
            if (includeSex) {
                for (let x = 0; x < sexesByAge[age.replace(/ /g,"_")].length; x++) {
                    if (x !== 0) {
                        buildingString += "& "
                    }
                    buildingString += sexesByAge[age.replace(/ /g,"_")][x] + " ";
                }
            }
            if (includeAge) {
                buildingString += age + " ";
            }
        });

        if (includeDistance) {
            buildingString += + heats[0].yards + "yd ";
        }
        let stroke = heats[0].stroke;
        if (shortenName) {
            buildingString += this.shortenStroke(stroke)
        } else {
            buildingString += stroke;
        }
        let relay = heats[0].relay;
        if (relay === 1) {
            buildingString += " Relay";
        }
        return buildingString;
    }

    static colorPicker(stroke) {
        if (stroke == null) {
            return "";
        }
        if (stroke.includes("Freestyle Relay")) {
            return "primary";
        } else if (stroke.includes("Free")) {
            return "success";
        } else if (stroke.includes("Med")) {
            return "dark";
        } else if (stroke.includes("Breast")) {
            return "dark";
        } else if (stroke.includes("Back")) {
            return "success";
        } else if (stroke.includes("fly") || stroke.includes("Fly")) {
            return "primary";
        } else if (stroke.includes("IM")) {
            return "success";
        } else {
            return "";
        }
    }

    static getColorHeatsheet(heatsheet, startNo) {
        return this.colorPicker(this.getStrokeText(false, false, heatsheet, startNo))
    }

    static shortenStroke(stroke) {
        if (stroke.includes("Freestyle")) {
            return "Free";
        } else if (stroke.includes("Breaststroke")) {
            return "Breast";
        } else if (stroke.includes("Backstroke")) {
            return "Back";
        } else if (stroke.includes("Butterfly")) {
            return "Fly"
        } else if (stroke.includes("Medley")) {
            return "Med"
        } else {
            return stroke;
        }
    }
}
