import React from 'react';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class EntriesTableClass extends React.Component {

    render() {
        return (
            <Container fluid={true}>
            { this.props.data.starts ?
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Age</th>
                      <th>Team</th>
                      <th>Seed Time</th>
                    </tr>
                  </thead>
                {this.renderRows(this.props.data, this.props.raceNo)}
                </Table>
            :
                <Container>
                    <Row style={{'min-height': '10vh'}} />
                    <Row>
                        <Col className="Loading">
                            <Spinner animation="border" />
                        </Col>
                    </Row>
                </Container>
            }
            </Container>
        );
    }

    renderRows(heatsheet, startNo) {
        var maxLanes = 8;
        if (heatsheet.noLanes) {
            maxLanes = heatsheet.noLanes;
        }
        var currentHeat = 0;
        var rowObjs = [];
        for (let i = 0; i < maxLanes; i++) {
            var heat = this.whichHeatIsSwimmer(heatsheet, startNo, i);
            var firstSwimmer = false;
            if (heat > -1 && currentHeat !== heat) {
                firstSwimmer = true;
                currentHeat = heat;
            }
            if (heat > -1) {
                var entry = heatsheet.starts[startNo].heats[heat].entries['' + (i + 1)]
                rowObjs[i] = (
                    <tr style={ firstSwimmer ? {'border-top': 'double'} : {}}>
                        <td>{i + 1}</td>
                        <td>{entry.name}</td>
                        <td>{entry.age}</td>
                        <td>{entry.team}</td>
                        <td>{(entry.time > 0) ? entry.time.toFixed(2) : ""}</td>
                    </tr>
                )
            } else {
                rowObjs[i] = (
                    <tr>
                        <td>{i + 1}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                )
            }
        }
        return <tbody>{rowObjs}</tbody>
    }

    whichHeatIsSwimmer(heatsheet, startNo, laneNo) {
        if (!heatsheet.starts[startNo]) {
            return -1;
        }
        var start = heatsheet.starts[startNo];
        var heatFound = -1;
        for (var i = 0; i < start.heats.length; i++) {
            if (start.heats[i].entries['' + (laneNo + 1)]) {
                heatFound = i;
            }
        }
        return heatFound;
    }

    renderArray(maxLanes, rows) {
        let buildingString = "";
        for (let i = 0; i < maxLanes; i++) {
            buildingString += rows[i];
        }
        return buildingString;
    }


}
