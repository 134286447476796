import React from 'react';
import '../views/styles/Board.css';
import './styles/Cards.css';
import Card from 'react-bootstrap/Card';
import Utility from '../utilities/Utilities'

const generateRaceText = (heats) => {
    return heats
    .map(heat => `${heat.eventNo}${heat.heatNo.toString().sup()}`)
    .join(" ");
}

const RaceText = (props) => {
    if (props.heatsheet && props.heatsheet.starts && props.heatsheet.starts[props.raceNo]) {
        let text = generateRaceText(props.heatsheet.starts[props.raceNo].heats);
        return(
            <Card.Text className="Current-race-text" dangerouslySetInnerHTML={{__html: text}}></Card.Text>
        )
    } else return <p></p>
}

export const TestCard = (props) => {
    return (
        <Card className="Current" border={Utility.getColorHeatsheet(props.heatsheet, props.raceNo)}>
            <Card.Header className="Current-heading" >{props.heading}</Card.Header>
            <Card.Body>
                <RaceText  {...props} />
                <p className="heat-name">{Utility.getStrokeText(true, true, props.heatsheet, props.raceNo)}</p>
            </Card.Body>
        </Card>
    )
}

const createUpcomingCard = (start, props, startIdx) => {
    let text = generateRaceText(start.heats);
    return (
        <Card className="upcoming-flex" border={Utility.getColorHeatsheet(props.heatsheet, startIdx)}>
            <Card.Text dangerouslySetInnerHTML={{__html: text}}></Card.Text>
        </Card>
    )
    
}

export const UpcomingCards = (props) => {
    let num_upcomming = 20;
    if (props.heatsheet && props.heatsheet.starts) {
        let selected_starts = props.heatsheet.starts.slice(
            Math.max(0, Math.min(props.currentstart + 2, props.heatsheet.starts.length)), 
            Math.min(props.heatsheet.starts.length, Math.max(1, props.currentstart + num_upcomming))
        );
        return selected_starts.map((start, index) => createUpcomingCard(start, props, index + props.currentstart + 2));

    } else return <p>Fetch Error!</p>
}
