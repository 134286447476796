import React from "react";
import { Router, Route } from "react-router-dom";
import firebase from 'firebase';
import BoardClass from "./views/Board"
import TestBoard from "./views/TestBoard"
import ClientClass from "./views/Client"
import ControlClass from "./views/Control"
import ReactGA from 'react-ga'
import Loading from './components/Loading.js'


export default class RouteClass extends React.Component {
    componentDidMount() {
    	ReactGA.pageview(window.location.pathname);
    }

    constructor(props) {
        super(props);
        var team = props.match.params.team;
        if (team === "kedhills") {
            team = "kedronhillscruisers";
        }
        console.log(team)

        this.state = {
            currentstart: 0,
            heatsheet: null,
            team: team,
        };

        let app = firebase.app();
        let firestore = app.firestore();
        let collection = firestore.collection('meetstatus');

        let pullNewHeatShet = (meetData) => {
            let heatSheetRef = firestore.collection('heatsheets').doc(meetData.currentmeet);
            let retreiver = heatSheetRef.get().then(doc => {
                console.log("Getting heat sheet data");
                if(!doc.exists) {
                    console.log("Heat sheet [" + meetData.currentmeet + "] doesn't exist!");
                } else {
                    console.log("Document found!");
                    let data = doc.data();
                    this.setState({
                        heatsheet: data,
                    });
                    localStorage.setItem('heatsheet', JSON.stringify(data));
                    localStorage.setItem('currentmeet', meetData.currentmeet);
                }
            });
        }

        let query = collection.doc(team);
        let observer = query.onSnapshot(querySnapshot => {
            query.get().then(doc => {
                if(!doc.exists) {
                    console.log("Current start doc doesn't exist!");
                } else {
                    let data = doc.data();
                    this.setState({
                        currentstart: data.currentstart,
                        currentmeet: data.currentmeet,
                    });
                    if (data.currentmeet !== localStorage.getItem('currentmeet')) {
                        pullNewHeatShet(data);
                    }
                }
            });
        }, err => {
            console.log(`Encountered error: ${err}`);
        });

        let findCurrentMeet = firestore.collection('meetstatus').doc(team).get().then(doc => {
            if(!doc.exists) {
                console.log(`meetstatus document does not exist for ${team}!`);
            } else {
                let meetData = doc.data()
                if (!meetData.currentmeet || meetData.currentmeet === "") {
                    console.log(`No current meet! ${JSON.stringify(doc.data())}`);
                    return;
                }
                if (meetData.currentmeet === localStorage.getItem('currentmeet')) {
                    console.log("Heat sheet up to date! Skipping pull");
                    this.setState({
                        heatsheet: JSON.parse(localStorage.getItem('heatsheet')),
                    });
                    return;
                }
                pullNewHeatShet(meetData);
            }
        }).catch(error => {
            console.log(`Encountered error: ${error}`);
        });

    }

    board = (props) => {
      return (
          <BoardClass {...this.state} {...props} {...this.props} />
      );
    }

    testboard = (props) => {
        return (
            <TestBoard {...this.state} {...props} {...this.props}/>
        );
    }

    control = (props) => {
        return (
            (this.state && this.state.team) ?
                <ControlClass {...this.state} {...props} {...this.props}/>
                :
                <Loading />
        );
    }

    betaClient = (props) => {
        return (
            (this.state && this.state.heatsheet && this.state.team) ?
             <BoardClass {...this.state} {...props} beta={true} {...this.props}/>
            :
            <Loading />
        );
    }

    client = (props) => {
          return (
              (this.state && this.state.heatsheet && this.state.team) ?
               <ClientClass {...this.state} {...props} {...this.props}/>
              :
              <Loading />
          );
    }

    fireTracking = (state) => {
        console.log("Google analytics event!")
    }

    render() {
        return (
            <>
              <Route path={`${this.props.match.url}/display_board`} component={this.board} />
              <Route exact path={`${this.props.match.url}`} component={this.betaClient} />
              <Route path={`${this.props.match.url}/console`} component={this.control} />
           </>
        );
    }
};
