import React from 'react'
import { Container, Row, Col, Card, CardDeck, Button } from 'react-bootstrap'
import { UpcomingCards, TestCard } from '../components/UpcomingCards'
import EntriesTable from '../components/EntriesTable'
import CurrentRace from '../components/CurrentRace'
import Utility from '../utilities/Utilities'
import './styles/Control.css'
import firebase from 'firebase';

export default class RaceController extends React.Component {
    constructor(props) {
        super(props);
        let app = firebase.app();
        let firestore = app.firestore();
        let collection = firestore.collection('meetstatus');
        let docRef = collection.doc(this.props.team);
        this.state = {
            docRef: docRef,
        };
    }

    changeRace = (differential, max) => {
        if (this.state.docRef) {
            this.state.docRef.update({currentstart: Math.max(-1, Math.min(this.props.currentstart + differential, max))});
        }
    };

    render() {

        return (
            this.props.heatsheet ?
                <Container fluid={true}>
                    <Row style={{ 'min-height': '5vh' }}>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header className="controlTableTitle">
                                    <CurrentRace heatsheet={this.props.heatsheet} raceNo={this.props.currentstart} />
                                    : {Utility.getStrokeLongText(true, true, true, true, this.props.heatsheet, this.props.currentstart)}
                                </Card.Header>
                            </Card>
                            <EntriesTable data={this.props.heatsheet} raceNo={this.props.currentstart}/>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header className="controlTableTitle">
                                    <CurrentRace heatsheet={this.props.heatsheet} raceNo={this.props.currentstart + 1} />
                                    : {Utility.getStrokeLongText(true, true, true, true, this.props.heatsheet, this.props.currentstart + 1)}
                                </Card.Header>
                            </Card>
                            <EntriesTable data={this.props.heatsheet} raceNo={this.props.currentstart + 1}/>
                        </Col>
                    </Row>
                    <Row style={{ 'min-height': '10vh' }}>
                        <Col md={{span:8, offset:2}} >
                            <CardDeck className="raceControllerButtonDeck">
                                <Card className="raceControllerButton">
                                    <Button className="raceControllerButton" onClick={() => this.changeRace(-1, this.props.heatsheet.starts.length)} style={{ 'min-height': '10vh' }}>Previous</Button>
                                </Card>
                                <Card className="raceControllerButton">
                                    <Button className="raceControllerButton" onClick={() => this.changeRace(1, this.props.heatsheet.starts.length)} style={{ 'min-height': '10vh' }}>Next</Button>
                                </Card>
                            </CardDeck>
                        </Col>
                    </Row>
                    <Row style={{ 'min-height': '5vh' }} />
                    <Row style={{ 'minHeight': '10vh' }}>
                        <Col>
                            <CardDeck className="bottom-deck">
                                <UpcomingCards {...this.props} {...this.state} />
                            </CardDeck>
                        </Col>
                    </Row>
                </Container>
            :
                <Container fluid={true} style={{ 'textAlign': 'center' }}>
                    <Row style={{ 'min-height': '40vh' }}>
                    </Row>
                    <Row>
                        <Col >
                            <div>No heatsheet is currently active.</div>
                            <div>You can upload or activate one in <a href={`/${this.props.team}/console/admin`}>Admin</a> on your Cruiser Console.</div>
                        </Col>
                    </Row>
                    <Row style={{ 'min-height': '1vh' }}/>
                    <Row>
                        <Col>
                            <Button href={`/${this.props.team}/console/admin`}>Go to Admin</Button>
                        </Col>
                    </Row>
                </Container>

        );
    }

}
