import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'

export default class NotAuthorized extends React.Component {
    render() {
        return (
            <Container className="SignIn">
                <Row style={{'min-height': '45vh'}} />
                <Row>
                    <Col className="SignInText">
                        You are not authorized to view this content.
                    </Col>
                </Row>
                <Row style={{'min-height': '2vh'}} />
                <Row>
                    <Col>
                        <Button className="SignInButton" onClick={() => this.props.signOut()}> Sign out </Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}
