import React from 'react';

//added a comment to test Docker build speed

export default class CurrentRace extends React.Component {

    render() {
        return (
            <div style={{display : 'inline'}}>
            {
            this.props.heatsheet.starts ?
                (
                this.props.heatsheet.starts[this.props.raceNo] ?
                    this.props.heatsheet.starts[this.props.raceNo].heats.map(this.renderHeat)
                :
                    ""
                )
            :
                ""
            }
            </div>
        );
    }

    renderHeat = (heat, index) => {
        return (
                <div style={{display : 'inline'}}> {(index === 0 ? "" : " & ")}{heat.eventNo}<sup>{heat.heatNo}</sup> </div>
        );
    }
}
